.adm-selector-container .adm-block-content {
  padding: 0px !important;
  background: transparent !important;
}

.adm-selector-container .adm-space-item {
  flex: 1;
}

.adm-selector-container .adm-selector {
  line-height: 0 !important;
}

.adm-selector-container .adm-selector-item {
  padding: 0px !important;
  border-radius: 8px;
}

.adm-selector-container .adm-selector-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.adm-image {
  border-radius: 8px;
}

.result-container {
  display: flex;
  gap: 12px;
}

.result-container-item {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.adm-page {
  max-width: 500px;
}